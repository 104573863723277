import React, { useState, useEffect, FC, createContext, Dispatch } from "react";
/*import { scroller, Element } from "react-scroll";*/
import Question from "./components/Question.tsx";
import Result from "./components/Result.tsx";
import QuizIntro from "./components/QuizIntro";
import BuzzFeedQuizProps from "./interfaces/BuzzFeedQuiz/buzzfeed_quiz.interface.tsx";
import ISelectedAnswer from "./interfaces/Answers/selected_answer.interface.tsx";
import ResultType from "./interfaces/Result/result.interface.tsx";
import { GiphyFetch } from '@giphy/js-fetch-api'

const GIPHY_KEY = 'mkEI7QsAdUROztRt4rhgxa6B6X8n5tuL';

interface ContextProps {
  selectedAnswers: ISelectedAnswer[];
  changeSelectedAnswers: Dispatch<React.SetStateAction<ISelectedAnswer[]>>;
}

export const QuizContext = createContext<ContextProps>({
  selectedAnswers: [{ questionIndex: 0, resultID: 0, answerIndex: 0 }],
  changeSelectedAnswers: () => []
});

const BuzzFeedQuiz: FC<BuzzFeedQuizProps> = (props) => {
  const {
    title,
    description,
    descriptionGif,
    questions,
    onResult,
    onRestart,
    facebookShareButton,
    facebookShareLink,
    twitterShareButton,
    twitterShareLink,
    twitterShareText,
    twitterShareHashtags,
    copyShareButton,
    copyShareLink,
    results,
  } = props;

  const [selectedAnswers, changeSelectedAnswers] = useState<ISelectedAnswer[]>(
    []
  );
  const [activeQuestion, setActiveQuestion] = useState(-1);
  const [resultsAvailable, changeResultsAvailable] = useState(false);
  const [finalResult, changeFinalResult] = useState<ResultType[]>([]);
  const [shareLinkClicked, changeShareLinkClicked] = useState(false);
  const [shareLinkAnimatingOut, changeShareLinkAnimatingOut] = useState(false);
  const [giphy, setGiphy] = useState(null);

  const setAnswer = (answerIndex, resultID) => {
    //console.log({ questionIndex: activeQuestion, answerIndex, resultID });
    //console.log([...selectedAnswers, { questionIndex: activeQuestion, answerIndex, resultID }]);
    
    changeSelectedAnswers([...selectedAnswers, { questionIndex: activeQuestion, answerIndex, resultID }]);
    setActiveQuestion(activeQuestion+1);
  }

  useEffect(() => {
    document.getElementsByName("Top")[0].scrollIntoView();

    if (
      selectedAnswers.length > 0 &&
      selectedAnswers.length === questions.length
    ) {
      const allAnswers = selectedAnswers.map((answer) => answer.resultID);
      const answerFreq: { [key: string]: number } = {};

      for (let i = 0; i < allAnswers.length; i++) {
        if (answerFreq[allAnswers[i]]) {
          answerFreq[allAnswers[i]]++;
        } else {
          answerFreq[allAnswers[i]] = 1;
        }
      }

      const greatestValue = Math.max(...Object.values(answerFreq));

      const mostFrequentResultID = Object.keys(answerFreq).find(
        (key) => answerFreq[key] === greatestValue
      );
       
      if (!resultsAvailable) {
        changeResultsAvailable(true);
        changeFinalResult(
          results.filter(
            (result) => Number(result.resultID) === Number(mostFrequentResultID)
          )
        );
      }
    }
  }, [selectedAnswers, questions, resultsAvailable, results]);

  useEffect(() => {
    if (shareLinkClicked) {
      setTimeout(() => {
        changeShareLinkAnimatingOut(true);
      }, 4500);

      setTimeout(() => {
        changeShareLinkClicked(false);
        changeShareLinkAnimatingOut(false);
      }, 4800);
    }
  }, [shareLinkClicked]);

  useEffect(() => {
    setGiphy(new GiphyFetch(GIPHY_KEY));
  }, []);

  const theQuestion = questions[activeQuestion];

  //console.log("Active Question:" , activeQuestion);
  //console.log("Selected Answers:" , selectedAnswers);

  return (
    <QuizContext.Provider
      value={{
        selectedAnswers: selectedAnswers,
        changeSelectedAnswers: changeSelectedAnswers
      }}
    >
      <div name="Top" className="mx-4 md:mx-10">
        <div>
          {activeQuestion === -1 && (
            <QuizIntro 
               descriptionGif = {descriptionGif}
               giphy = {giphy}
               title = {title}
               description= {description}
               setActiveQuestion = {setActiveQuestion}
            />
          )}
          
          {activeQuestion > -1 && questions ? (
            Array.isArray(questions) && questions.length > 0 ? (
              <>
              { theQuestion && 
                <ol
                  id="main_questions_container"
                >
                 
                  <Question
                    item={theQuestion}
                    maxQuestions={questions.length}
                    activeQuestion={true}
                    questionIndex={activeQuestion}
                    resultsAvailable={theQuestion.resultsAvailable}
                    setAnswer = {setAnswer}
                    giphy = {giphy}
                  />
                   
                </ol>
               } 
                <Result
                  title={title}
                  resultsAvailable={resultsAvailable}
                  finalResult={finalResult}
                  facebookShareButton={facebookShareButton}
                  facebookShareLink={facebookShareLink}
                  twitterShareButton={twitterShareButton}
                  twitterShareLink={twitterShareLink}
                  twitterShareText={twitterShareText}
                  twitterShareHashtags={twitterShareHashtags}
                  copyShareButton={copyShareButton}
                  giphy = {giphy}
                  copyShareLink={copyShareLink}
                  shareLinkClicked={shareLinkClicked}
                  changeShareLinkClicked={changeShareLinkClicked}
                  shareLinkAnimatingOut={shareLinkAnimatingOut}
                  changeShareLinkAnimatingOut={changeShareLinkAnimatingOut}
                  changeResultsAvailable={changeResultsAvailable}
                  changeSelectedAnswers={changeSelectedAnswers}
                  changeFinalResult={changeFinalResult}
                  onResult={onResult}
                  onRestart={onRestart}
                  setActiveQuestion={setActiveQuestion}
                />
              </>
            ) : null
          ) : null}
        </div>
      </div>
    </QuizContext.Provider>
  );
};

BuzzFeedQuiz.defaultProps = {
  title: "",
  description: "",
  generalBackgroundColor: "",
  generalFontColor: "",
  autoScroll: true,
  facebookShareButton: true,
  facebookShareLink: "",
  twitterShareButton: true,
  twitterShareLink: "",
  twitterShareText: "",
  twitterShareHashtags: [],
  copyShareButton: true,
  copyShareLink: "",
  onResult: () => {},
  onAnswerSelection: () => {},
  onRestart: () => {},
  questions: [],
  results: [],
};

export default BuzzFeedQuiz;
