import ImageGif from './ImageGif.js'
import StartQuizButton from "./StartQuizButton.js";

const QuizIntro = ({descriptionGif, giphy, title, description, setActiveQuestion}) => {

    const processPara = (para, key) => {
        return (
                (para === "") ? (<p className="mt-4"></p>) : (
                <p key={key} className="text-mobileCopy md:text-desktopCopy max-w-lg mx-auto">{para}</p>
                )
        )
    }

   return ( 
        <div className="white-box">
             {(giphy && descriptionGif) ? (
                <div className="inline-block m-0 mx-auto w-full">
                    <ImageGif  
                        className="rbq_intro_image text-cisco-blue"
                        alt={`What type of leader are you?`}
                        giphy={giphy}
                        giphyId={descriptionGif}
                        attrPosition="top-2 right-2"
                        type="gif"
                    /> 
                </div>
              ): null}
              {title ? (<h1 className="text-mobileHeader md:text-desktopHeader mt-6 mb-4 text-cisco-blue md:m-5 md:text-3xl">{title}</h1>): null }
              {description ?
                  description.map((para, index) => (
                    processPara(para, index)
               )): null }
              <StartQuizButton setActiveQuestion={setActiveQuestion} />
        </div>
    )
    
};
  
export default QuizIntro;
  