import { FC, useMemo } from "react";
import ResultProps from "../interfaces/Result/result_props.interface";
import ImageGif from './ImageGif.js';
import DOMPurify from 'dompurify';

//import CopyLinkButton from "./ShareButtons/CopyLinkButton.tsx";
//import BrowserShareButton from "./ShareButtons/BrowserShare.tsx";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";

const Result: FC<ResultProps> = (props) => {
  const {
    resultsAvailable,
    finalResult,
    twitterShareText,
    twitterShareHashtags,
    giphy,
    shareLinkClicked,
    setActiveQuestion,
    changeShareLinkClicked,
    shareLinkAnimatingOut,
    changeShareLinkAnimatingOut,
    changeResultsAvailable,
    changeSelectedAnswers,
    changeFinalResult,
    onResult,
    onRestart,
  } = props;

  const handleRetakeQuiz = () => {
    changeResultsAvailable(false);
    changeSelectedAnswers([]);
    changeFinalResult([]);

    if (shareLinkClicked) {
      changeShareLinkClicked(false);
    }

    if (shareLinkAnimatingOut) {
      changeShareLinkAnimatingOut(false);
    }

    if (onRestart) {
      if (typeof onRestart === "function") {
        onRestart();
      }
      setActiveQuestion(-1);
    }
  };

  useMemo(() => {
    const generalOnResultFunction = () => {
      if (onResult) {
        if (typeof onResult === "function") {
          onResult();
        }
      }
    };

    if (resultsAvailable && finalResult.length > 0) {
      if (finalResult[0].onResult) {
        if (typeof finalResult[0].onResult === "function") {
          finalResult[0].onResult();
        } else {
          generalOnResultFunction();
        }
      } else {
        generalOnResultFunction();
      }
    }
  }, [resultsAvailable, finalResult, onResult]);

  const santizeHTML = (text) => {
    
    const htmlString = text;
    const cleanedHTML = DOMPurify.sanitize(htmlString);
    return { __html: cleanedHTML };

  };

  if (resultsAvailable && finalResult.length > 0) {
    return (
      <>
      <div className="white-box" name="Result">
       
        <div className="rbq_result_inner_container">
          <div className="rbq_result_inner_description_container">
           
            <div className="rbq_result_inner_image_container mt-4">
                <ImageGif
                  className="result_image mb-2"
                  type={finalResult[0].type}
                  giphy={giphy}
                  giphyId={finalResult[0].giphyId}
                  alt={`${finalResult[0].title}`}
                />
            </div>
            
            <div className="max-w-xl mx-auto mt-4 mb-8">
              <h2  className="text-cisco-blue text-mobileHeader md:text-desktopHeader">
              {finalResult[0].pretitle}
              </h2> 
              <h3 className="text-cisco-blue font-bold mt-1 mb-4 text-mobileHeader md:text-desktopHeader">
                {finalResult[0].title}
              </h3>

              {finalResult[0].description ?
                    finalResult[0].description.map((para, index) => (
                      <p key={index} className="text-md mb-4 text-mobileResult md:text-desktopResult text-gray-600">{para}</p>
                    ))
              : null }
            </div>
          </div>
          
        </div>
        
      </div>

      <div className="white-box mt-5 pt-10 md:mt-10">
        <div className="productLink text-center mx-auto max-w-xl">
         

          <h2 className="text-mobileHeader md:text-desktopHeader text-cisco-blue3 font-bold mx-5 mb-4 md:mx-12 leading-11" dangerouslySetInnerHTML={santizeHTML(finalResult[0].productTitle)}></h2>
          
          <p className="mb-4 text-mobileResult text-gray-600 md:text-desktopResult md:mx-4 resultText" dangerouslySetInnerHTML={santizeHTML(finalResult[0].productText)}></p>
          
          <a href={finalResult[0].productButtonUrl} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="block font-bold text-mobileButton md:text-desktopButton cursor-pointer border-0 bg-cisco-blue2 text-white px-4 py-4 text-center my-2 rounded-4xl text-md hover:bg-white hover:bg-cisco-blue md:my-5 md:mx-10">
                {finalResult[0].productButtonText}
          </a>

          <p><button onClick={handleRetakeQuiz} className="block my-2 text-gray-600 underline hover:text-cisco-blue font-bold cursor-pointer text-mobileResult md:text-desktopResult md:my-5 mx-auto">Take the quiz again</button></p>

          <div className="rbq_result_footer">
          
          <h3 className="text-xs text-gray-400 md:text-sm md:mb-4 mt-5">Share:</h3>
          <ul className="rbq_share_links_list flex mx-auto justify-center" aria-label="share">
          
            <li className="mx-0.5 scale-75 md:scale-100"> 
               <TwitterShareButton url={window.location.href + finalResult[0].shareLink} quote={twitterShareText}  title={`${finalResult[0].pretitle} ${finalResult[0].title}`} ><TwitterIcon size={48} round /></TwitterShareButton>
            </li>
            <li className="mx-0.5 scale-75 md:scale-100">
              <FacebookShareButton url={window.location.href + finalResult[0].shareLink} quote={twitterShareText}><FacebookIcon size={48} round /></FacebookShareButton>
           </li>
           <li className="mx-0.5 scale-75 md:scale-100">
               <LinkedinShareButton url={window.location.href + finalResult[0].shareLink} quote={`${twitterShareText} ${twitterShareHashtags}`} title={`${finalResult[0].pretitle} ${finalResult[0].title}`} summary={`${finalResult[0].description.join('. ')}`}><LinkedinIcon size={48} round /></LinkedinShareButton>
            </li>
          </ul>
        </div>

        </div>
      </div>
      </>
    );
  } else {
    return null;
  }
};

export default Result;
