import { useState, useEffect, useRef } from "react";

const ProgressBar = ({progress, max }) => {
    const start = Math.max(0,(progress -1 )) / max * 100;
    const [ percentage, setPercentage ] = useState(start);
    const nodeRef = useRef(null);
    
    useEffect(() => {
        const perc = progress/max * 100;
        setTimeout(()=>{
            setPercentage(perc);
        },50);   
    }, [max, progress, setPercentage])

    return (
        <div className="md:mx-10">
           
        <div className="progress-bar bg-gray-300 h-1 rounded-xl mx-3 mb-4 mx-auto md:h-2 md:w-full md:mx-auto">
            <div ref={nodeRef} className="active bg-cisco-blue2 h-1 md:h-2 rounded-xl transition-all" style={{ "width" : `${percentage}%`}}></div>
        </div>
            
        </div>
    )
    
};
  
export default ProgressBar;
  