
let imgs = [
    'https://giphy.com/gifs/bill-murray-groundhog-day-transparent-j8sVwJid3NdjG',
    'https://res.cloudinary.com/stealthman22/image/upload/v1586308023/new-portfolio/hero/two-cargo-ships-sailing-near-city-2144905.jpg',
  ];

export const questions = [ 
{
    question: "It’s 3am. What are you doing?",
    questionRelativeToImage: "overlap",
    answerArrangement: "tile",
    answers: [
      {
        answer: "Soothing your toddler back to sleep",
        resultID: 0,
        type: "gif",
        giphyId: "NzfSMKPlnpJkNHJamr",
        backgroundImageSrc: imgs[1]
    },
    {
        answer: "Relationship-building with a client",
        resultID: 1,
        type: "gif",
        giphyId: "IagLXBSfnGYQ2yQWNh",
        backgroundImageSrc: imgs[1]

    },
    {
      answer: "Fast asleep so you can go into the office early tomorrow",
        resultID: 2,
        type: "gif",
        giphyId: "Tiz7Ute5uuiSKyWqPP",
        backgroundImageSrc: imgs[1]
      
  }
    ],
  },
  {
    question: "What’s your mantra?",
    questionRelativeToImage: "overlap",
    answerArrangement: "tile",
    answers: [
        {
            answer: "We must continue to adapt",
            resultID: 0,
            type: "gif",
            giphyId: "Tes2L7WzZyEOi8et1k",
            backgroundImageSrc: imgs[1]
        },
        {
            answer: "Let's be amazing together",
            resultID: 1,
            type: "gif",
            giphyId: "3ohhwoy4AB7fXp0GVq",
            backgroundImageSrc: imgs[1]

        },
        {
            answer: "I want it all... and I want it now",
            resultID: 2,
            type: "gif",
            giphyId: "FYSW3c4eJb6G4",
            backgroundImageSrc: imgs[1]
          
      }
    
    ],
},

  {
    question: "How would you describe your team?",
    questionRelativeToImage: "overlap",
    answerArrangement: "tile",
    answers: [
      {
        answer: "Spread across multiple time zones",
        resultID: 0,
        type: "gif",
        giphyId: "H3qWY13eGZUomKxOs6",
        backgroundImageSrc: imgs[1]
    },
    {
      answer: "Creativity runs in their veins",
      resultID: 1,
      type: "gif",
      giphyId: "P2Y5CA2lJRmDbUux8T",
      backgroundImageSrc: imgs[1]
    },
    {
      answer: "One word: exceptional",
      resultID: 2,
      type: "gif",
      giphyId: "kHOts8xerNiFI6KdCO",
      backgroundImageSrc: imgs[1]
    }
    ],
  },

  {
    question: " The government announces that hybrid/remote working is now illegal. How do you react?",
    questionRelativeToImage: "overlap",
    answerArrangement: "tile",
    answers: [
      {
        answer: "It's not fair!",
        resultID: 0,
        type: "gif",
        giphyId: "3o6wrvdHFbwBrUFenu",
        backgroundImageSrc: imgs[1]
      },
      {
        answer: "That's strange...",
        resultID: 1,
        type: "gif",
        giphyId: "Wgb2FpSXxhXLVYNnUr",
        backgroundImageSrc: imgs[1]
      },
      {
        answer: "Makes no difference to me",
        resultID: 2,
        type: "gif",
        giphyId: "qAB5xbv44cCAg",
        backgroundImageSrc: imgs[1]
      }
    ],
  },

  {
    question: "How do you approach team building?",
    questionRelativeToImage: "overlap",
    answerArrangement: "tile",
    answers: [
      {
        answer: "Ask the team what they want to do",
        resultID: 0,
        type: "gif",
        giphyId: "RcXzaKH7hT7pEJv8J8",
        backgroundImageSrc: imgs[1]
      },
      {
        answer: "Take them out on the town",
        resultID: 1,
        type: "gif",
        giphyId: "3o85xBF8KBaQbpzij6",
        backgroundImageSrc: imgs[1]
      },
      {
        answer: "Organise lunch & learn sessions",
        resultID: 2,
        type: "gif",
        giphyId: "l3fZVDjkXugk3VlUQ",
        backgroundImageSrc: imgs[1]
      }
    ],
  }


]


export const results = [
    {
      pretitle: "You're an",
      title: "Agile Leader",
      description: ["A work-from-home champion where “home” is where the laptop is. Everywhere and anywhere.", "You’re a nimble fox, and you adapt like no one else in the biz. And as for multitasking – you can pull off family life, remote meetings and in-person events like a pro. Your team is curious, proactive, global – and plain awesome."],
      type: "gif",
      giphyId: "VdbCyYkA2ewswzrlzI",
      imageAttribution: "Your photo attribution text goes here",
      productTitle: "Tools and tech<br/>handpicked for you",
      productText: "Flexibility is key. You need products that let you work seamlessly from anywhere. View our Cisco noise-cancelling <a href=\"https://www.cisco.com/c/en/us/products/collaboration-endpoints/headsets/index.html?dtid=osscdc000283#~900-series&CCID=cc004019\">headphones</a>, letting you work from coffee shops, airports or a busy home with no interruptions. Our <a href=\"https://hardware.webex.com/products/desk-mini?CCID=cc004019\">Desk Mini</a> lets you experience top quality conferencing from any room and our <a href=\"https://www.cisco.com/c/en_uk/products/security/promotions-free-trials.html?CCID=cc004019\">network security</a> solutions keep your devices fully protected in and out of the office.",
      productButtonText: "Discover more",
      productButtonUrl: "https://www.cisco.com/c/en_uk/solutions/small-business.html#~products?CCID=cc004019&leader=agile",
      shareLink: "agile.html",
      resultID: 0,
    },
    {
      pretitle: "You're a",
      title: "Hands-On Leader",
      description: ["You’re in the nooks and crannies of your business. No more so than when it comes to your team.", "Creative collaboration. Coaching. Company culture. You’re all about teamwork, efficiency and creativity. If a prospective employee’s demeanour doesn’t scream “team player”, they don’t get hired. You know how to get the job done and enjoy the process."],

      type: "gif",
      giphyId: "UatRnEUNX8iCQ",
      productTitle: "Tools and tech<br/>handpicked for you",
      productText: "As someone who prioritises productivity and team work, you might want to check out the latest <a href=\"https://www.cisco.com/c/en/us/products/collaboration-endpoints/webex-desk-series/index.html?socialshare=videoblade-webex-desk-pro&CCID=cc004019\">Cisco Desk Pro</a>. It’s the ideal work and collaboration station for executives, knowledge workers and productivity champions. Enjoy all-in-one HD video and voice features for a virtual experience that rivals in-person collaboration.",
      productButtonText: "Discover more",
      productButtonUrl: "https://www.cisco.com/c/en_uk/solutions/small-business.html#~products?CCID=cc004019&leader=handson",
      shareLink: "hands-on.html",
      resultID: 1,
    },
    {
      pretitle: "You're a",
      title: "Leader That Wants It All",
      type: "gif",
      giphyId: "gRUvUqTZDUEGrGVLLf",
      shareLink: "want-it-all.html",
      productTitle: "Tools and tech<br/>handpicked for you",
      productText: "You like to keep an eye on the details and you know your business inside and out. Our best in-class, cloud-first technologies from <a href=\"https://meraki.cisco.com/experiences/hybrid-workforce/?CCID=cc004019\">Cisco Meraki</a> can help with that. You can optimize, secure, and connect every network experience for your hybrid workforce. Plus, keep your teams productive and engaged with our <a href=\"https://www.cisco.com/c/dam/en/us/products/collateral/collaboration-endpoints/collaboration-room-endpoints/cisco-webex-rooms-brochure.pdf?CCID=cc004019&oid=otrco020384\">Webex Devices</a>. Whether remote or in the same room, you know teams work better when they can see each other.",
      productButtonText: "Discover more",
      productButtonUrl: "https://www.cisco.com/c/en_uk/solutions/small-business.html#~products?CCID=cc004019&leader=wantsitall",
      description: ["You’re a five-days-in-the-office boss, and you have your finger on the pulse of your business like no one else.", "“Go big or go home” hangs on your wall in bold print. And your team knows you demand only the best for clients. Like a music conductor, you guide each team precisely, so everything works as one."],
      resultID: 2,
    }
]