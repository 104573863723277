import CiscoHeader from "./quiz/components/CiscoHeader.js";
import BuzzFeedQuiz from "./quiz/BuzzFeedQuiz.tsx";
import {questions, results} from "./quiz.js"

const App = () => {

  return (
  
    <main className="container mx-auto w-full max-w-full">
        <CiscoHeader />
          <div className="background py-mobileContainer md:py-desktopContainer">
            <BuzzFeedQuiz
              title={"What type of leader are you?"}
              description={["What does it mean to be a leader?",
                            "Do you dictate from a distance or are you more hands-on?",
                            "",
                            "Take this quiz to find out and discover the products that'll help you up your game."]}
              descriptionGif = "YwpylUojkfOZa"
              autoScroll={false}
              onRestart={() => false}
              onResult={() => false}
              onAnswerSelection={(questionIndex, answerIndex, resultID) => { return false; }}
            
              copyShareButton={false}
              copyShareLink={"This text was copied using the copyShareLink prop."}
              questions = {questions}
              results = {results}
            />
       </div>
    </main>
  );
};

export default App;