import { FC, useContext } from "react";
import AnswersProps from "../interfaces/Answers/answers_props.interface";
import { useResizeDetector } from "react-resize-detector";
import { QuizContext } from "../BuzzFeedQuiz.tsx";
import ImageGif from './ImageGif.js'

const Answers: FC<AnswersProps> = ({
  item,
  questionIndex,
  resultsAvailable,
  giphy,
  onAnswerSelection,
  setAnswer,
  changeSelectedAnswerResponse,
}) => {
  const { selectedAnswers } = useContext(QuizContext);

  const { ref } = useResizeDetector();
  const handleAnswerSelection = (
    questionIndex: number,
    answerIndex: number,
    resultID: number,
    showResponse: boolean,
    specificHandleAnswerSelection?: (
      questionIndex: number,
      answerIndex: number,
      resultID: number
    ) => void
  ) => {
    const handleGeneralAnswerSelection = () => {
      if (onAnswerSelection) {
        if (typeof onAnswerSelection === "function") {
          onAnswerSelection(questionIndex, answerIndex, resultID);
        }
      }
      setAnswer( questionIndex, answerIndex, resultID );
    };

    if (specificHandleAnswerSelection) {
      if (typeof specificHandleAnswerSelection === "function") {
        specificHandleAnswerSelection(questionIndex, answerIndex, resultID);
      } else {
        handleGeneralAnswerSelection();
      }
    } else {
      handleGeneralAnswerSelection();
    }

  };

  return (
    <div>
      {item.answers.map((answerEl, answerIndex) => {
        const questionAnswered = selectedAnswers.some(
          (el) => el.questionIndex === questionIndex
        );

        const answerSelected = selectedAnswers.some(
          (el) =>
            el.questionIndex === questionIndex && el.answerIndex === answerIndex
        );

        return (
          <div
            className={`answer-button max-w-480 md:max-w-600 mx-auto mb-8 ${
              resultsAvailable ? "rbq_results_available" : ""
            } ${
              item.answerArrangement === "row"
                ? "rbq_answer_row_arrangement"
                : ""
            } ${questionAnswered ? "rbq_question_answered" : ""} ${
              answerSelected ? "rbq_answer_selected" : ""
            }`}
            key={answerIndex}
            onClick={() => {
              changeSelectedAnswerResponse({
                title: "",
                description: "",
                image: "",
                imageAttribution: "",
              });
              const revealRes = answerEl.revealResponse;
              let showResponse = false;

              if (
                revealRes &&
                (revealRes.title ||
                  revealRes.description ||
                  revealRes.image ||
                  revealRes.imageAttribution)
              ) {
                showResponse = true;
                changeSelectedAnswerResponse(revealRes);
              }

              handleAnswerSelection(
                questionIndex,
                answerIndex,
                answerEl.resultID,
                showResponse,
                answerEl.onAnswerSelection
              );
            }}
            ref={ref}
          >
            <div
              className={`rbq_individual_answer_container cursor-pointer
               ${questionAnswered ? "rbq_question_answered" : ""} 
               ${answerSelected ? "rbq_answer_selected" : ""} 
               ${resultsAvailable ? "rbq_results_available" : ""} 
              mx-6`}
            >
              <ImageGif
                className="rbq_answer_image opacity-90"
                type={answerEl.type}
                giphy={giphy}
                giphyId={answerEl.giphyId}
                alt={`${answerEl.answer}`}
                attrPosition = "top-2 right-2"
              />
                
              <div className="">
                {answerEl.answer && (
                  <p className="answer bg-cisco-blue2 rounded-4xl text-white my-3 text-xs px-4 py-2 md:text-lg">{answerEl.answer}</p>
                )}
                {answerEl.imageAttribution && (
                  <p
                    className={`rbq_answer_image_attribution ${
                      questionAnswered ? "rbq_question_answered" : ""
                    } ${answerSelected ? "rbq_answer_selected" : ""}`}
                  >
                    {answerEl.imageAttribution}
                  </p>
                )}
              </div>
            
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Answers;
