import { FC, memo, useState } from "react";
import ProgressBar from "./ProgressBar.js";
import QuestionProps from "../interfaces/Question/question_props.interface.tsx";
import Answers from "./Answers.tsx";
import QuestionResponse from "./QuestionResponse.tsx";

// Memoization important to keep entire quiz from re-rendering upon every answer selection
const Question: FC<QuestionProps> = memo(
  ({
    item,
    questionIndex,
    generalBackgroundColor,
    generalFontColor,
    resultsAvailable,
    maxQuestions,
    onAnswerSelection,
    setAnswer,
    activeQuestion,
    giphy
  }) => {
    const [selectedAnswerResponse, changeSelectedAnswerResponse] = useState<{
      title?: string | JSX.Element;
      description?: string | JSX.Element;
      image?: string;
      gif?: string;
      imageAttribution?: string;
    }>({
      title: "",
      description: "",
      image: "",
      imageAttribution: "",
    });

    return (
      <li
        className={`white-box ${(activeQuestion)?'active':`hidden`}`}
        key={`Question${questionIndex}`}
      >
        <ProgressBar max={maxQuestions} progress={questionIndex+1} />

        <h2 className="text-3xl font-bold text-cisco-blue2">Q{questionIndex + 1}</h2>
        <h3 className="text-xl mt-2 mb-7 text-cisco-blue">
          {item.question ? item.question : null}
        </h3>
        
        {item.answers &&
          (Array.isArray(item.answers) && item.answers.length > 0 ? (
            <>
              <Answers
                item={item}
                questionIndex={questionIndex}
                resultsAvailable={resultsAvailable}
                onAnswerSelection={onAnswerSelection}
                generalBackgroundColor={generalBackgroundColor}
                generalFontColor={generalFontColor}
                changeSelectedAnswerResponse={changeSelectedAnswerResponse}
                setAnswer={setAnswer}
                giphy={giphy}
              />
              <QuestionResponse
                questionIndex={questionIndex}
                title={selectedAnswerResponse.title}
                description={selectedAnswerResponse.description}
                image={selectedAnswerResponse.image}
                imageAttribution={selectedAnswerResponse.imageAttribution}
              />
            </>
          ) : null)}
      </li>
    );
  }
);

export default Question;
