import { useState, useEffect, memo } from 'react';
import GiphyLogo from "../../img/giphy-small.png";
import GiphyLogoLarge from "../../img/giphy-large.png";

const ImageGif = ({image, id, type, className, alt, giphy, giphyId, imageAttribution, imageAttributionUrl, attrPosition }) => {
    const [imageData, setImageData] = useState(false);
    
    useEffect(()=> {
        if (type !== "gif") return;
        getGifImageData();
    });

    const getGifImageData = async () => {
        if(!giphyId) return;

        const { data } = await giphy.gif(giphyId)
        setImageData(data);
    }

    const imageLoaded = (el) => {
        el.target.classList.remove('hidden');
        el.target.parentNode.querySelector('.preload').classList.add('hidden');
    } 

    if (!attrPosition) {
        attrPosition = "bottom-2 right-2"
    }

    if (type === 'gif'){ 
        return (
           
           
                <div className="relative block w-full max-w-480 mx-auto md:max-w-600">
                    {/*<Gif 
                        className={className}
                        gif={imageData}
                        width={480}
                        noLink={true}
                        borderRadius={7}
                        backgroundColor="white"
                        hideAttribution={true}
                        />
                    */}
                    <div className={`preload block w-full max-w-480 min-w-320 md:max-w-600 md:max-w-full rounded-2xl bg-gray-300 aspect-video flex justify-around items-center`}>
                    
                        <svg width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                            <circle cx="15" cy="15" r="15">
                                <animate attributeName="r" from="15" to="15"
                                        begin="0s" dur="0.8s"
                                        values="15;9;15" calcMode="linear"
                                        repeatCount="indefinite" />
                                <animate attributeName="fill-opacity" from="1" to="1"
                                        begin="0s" dur="0.8s"
                                        values="1;.5;1" calcMode="linear"
                                        repeatCount="indefinite" />
                            </circle>
                            <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                                <animate attributeName="r" from="9" to="9"
                                        begin="0s" dur="0.8s"
                                        values="9;15;9" calcMode="linear"
                                        repeatCount="indefinite" />
                                <animate attributeName="fill-opacity" from="0.5" to="0.5"
                                        begin="0s" dur="0.8s"
                                        values=".5;1;.5" calcMode="linear"
                                        repeatCount="indefinite" />
                            </circle>
                            <circle cx="105" cy="15" r="15">
                                <animate attributeName="r" from="15" to="15"
                                        begin="0s" dur="0.8s"
                                        values="15;9;15" calcMode="linear"
                                        repeatCount="indefinite" />
                                <animate attributeName="fill-opacity" from="1" to="1"
                                        begin="0s" dur="0.8s"
                                        values="1;.5;1" calcMode="linear"
                                        repeatCount="indefinite" />
                            </circle>
                        </svg>
                    </div>
                    { imageData && (
                         <>
                            <img src={imageData.images.downsized_large.url} alt={alt} className={`${className} w-480 md:w-600  rounded-2xl hidden`} onLoad={imageLoaded} />
                            <p className={`absolute ${attrPosition} text-white text-xs max-w-480 max-w-600 bg-zinc-800/50 p-1`}>
                                <a href={imageData.url} target="_blank" rel="noopener noreferrer" className="mx-auto block w-[75%] text-orange-500 underline uppercase underline-offset-2">
                                    <picture className="">
                                        <source srcset={GiphyLogo} media="(min-width: 400px)" />
                                        <source srcset={GiphyLogo} />
                                        <img src={GiphyLogo} alt="Powered by GIPHY"/>
                                    </picture>
                                </a>
                            </p>
                        </>
                    ) }
                </div>
        )
    } else {
      return (
        <img
              className={className}
              src={image}
              alt={alt}
            />
      );
    }

};
  
export default memo(ImageGif);
  