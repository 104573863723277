import { memo } from "react";


// Memoization important to keep entire quiz from re-rendering upon every answer selection
const StartQuizButton = memo(
  ({
    setActiveQuestion
  }) => {


    const onClick = () => {
        setActiveQuestion(0);
    }
    return (
      <button
        className={`text-mobileButton md:text-desktopButton border-0 bg-cisco-blue2 text-white px-20 py-4 text-center mt-8 rounded-4xl text-md hover:bg-cisco-blue`} onClick={onClick}>
        Start the Quiz!
      </button>
    );
  }
);

export default StartQuizButton;
